import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {DetailHeader} from "../../common/ui/detailsection/DetailHeader";
import {DetailView, FieldInfo, Formats} from "../../common/ui/detailsection/DetailView";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";
import {getNumberFromOwnProps} from "../../common/util/Util";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {FileInfo, FileStatus} from "../../data/model";
import {getFileStatusTranslation} from "../../data/Util";

interface FileDetailPageState {
  file: FileInfo;
  isLoading: boolean;
  success: boolean;
  error: Error;
}

interface FileDetailPageProps {
  fileId: number;
}

const messages = defineMessages({
  ID: {
    id: "studio.file.detail.id",
    defaultMessage: "Id",
  },
  Status: {
    id: "studio.file.detail.status",
    defaultMessage: "Status",
  },
  Name: {
    id: "studio.file.detail.name",
    defaultMessage: "Name",
  },
  MimeType: {
    id: "studio.file.detail.mime-type",
    defaultMessage: "Mime Type",
  },
  Size: {
    id: "studio.file.detail.size",
    defaultMessage: "Size",
  },
  Path: {
    id: "studio.file.detail.path",
    defaultMessage: "Path",
  },
  FirstScanTime: {
    id: "studio.file.detail.first-scan-time",
    defaultMessage: "First Scan Time",
  },
  LastScanTime: {
    id: "studio.file.detail.last-scan-time",
    defaultMessage: "Last Scan Time",
  },
  Error: {
    id: "studio.file.detail.error",
    defaultMessage: "Error",
  },
  Type: {
    id: "studio.file.detail.type",
    defaultMessage: "Type",
  },
  Data: {
    id: "studio.file.detail.data",
    defaultMessage: "Data",
  },
  Style: {
    id: "studio.file.detail.style",
    defaultMessage: "Style",
  },
  StatusSuccessDescription: {
    id: "studio.file.detail.status-description.success",
    defaultMessage: "The file was successfully crawled as geospatial data or style.",
  },
  StatusSkippedDescription: {
    id: "studio.file.detail.status-description.skipped",
    defaultMessage: "The file was not recognized as a supported geospatial data type.",
  },
  StatusFailedDescription: {
    id: "studio.file.detail.status-description.failed",
    defaultMessage: "The file was recognized as a supported type but failed to crawl successfully. See Error for more.",
  },
});

const getFileStatusDescription = (status: FileStatus): FormattedMessage.MessageDescriptor => {
  switch (status) {
  case FileStatus.SKIPPED:
    return messages.StatusSkippedDescription;
  case FileStatus.SUCCESS:
    return messages.StatusSuccessDescription;
  case FileStatus.FAILED:
    return messages.StatusFailedDescription;
  }
};

export class FileDetailPageComponent extends React.Component<InjectedIntlProps & FileDetailPageProps & WithApiProperties, FileDetailPageState> {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isLoading: true,
      success: false,
      error: null,
    };
  }

  componentDidMount() {
    const {fileId} = this.props;
    this.getFileById(fileId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const fileId = nextProps.fileId;
    if (fileId && this.props.fileId !== fileId) {
      this.getFileById(fileId);
    }
  }

  getFileById(id: number) {
    if (id == null) {
      this.updateState({isLoading: false, error: "Incorrect item id: " + id});
    }
    this.updateState({isLoading: true});
    this.props.api.getFileById(id).then(
        (file) => this.updateState({file, isLoading: false, success: true}),
    ).catch(
        (error) => this.updateState({file: null, isLoading: false, error}),
    );
  }

  updateState = (updatedPart) => this.setState(Object.assign({}, this.state, updatedPart));

  render() {
    const {isLoading, success, error, file} = this.state;
    const {intl} = this.props;
    if (!isLoading && error) {
      return <ErrorDisplay error={error}/>;
    } else if (!isLoading && success) {
      const fields: FieldInfo[] = [
        {
          key: "Id",
          name: intl.formatMessage(messages.ID),
          value: file.id,
        },
        {
          key: "Path",
          name: intl.formatMessage(messages.Path),
          value: file.filePath,
        },
        {
          key: "Status",
          name: intl.formatMessage(messages.Status),
          value:
              <span>
                <span className="status">
                  {getFileStatusTranslation(file.status, intl)}
                </span>
                <span className="status-description">
                  {intl.formatMessage(getFileStatusDescription(file.status))}
                </span>
              </span>,
        },
        {
          key: "MimeType",
          name: intl.formatMessage(messages.MimeType),
          value: file.mimeType,
        },
        {
          key: "Size",
          name: intl.formatMessage(messages.Size),
          value: file.fileSize,
          format: Formats.FILESIZE,
        },
        {
          key: "FirstScanTime",
          name: intl.formatMessage(messages.FirstScanTime),
          value: file.firstScanTime,
          format: Formats.DATETIME,
        },
        {
          key: "LastScanTime",
          name: intl.formatMessage(messages.LastScanTime),
          value: file.lastScanTime,
          format: Formats.DATETIME,
        },
      ];
      if (file.type) {
        fields.push({
          key: "Type",
          name: intl.formatMessage(messages.Type),
          value: file.type,
        });
      }
      if (file.dataId) {
        fields.push({
          key: "Data",
          name: intl.formatMessage(messages.Data),
          value: <Link to={`/data/${encodeURIComponent(file.dataId)}`}>{file.dataTitle}</Link>,
        });
      }
      if (file.styleId) {
        fields.push({
          key: "Style",
          name: intl.formatMessage(messages.Style),
          value: <Link to={`/styles/${file.styleId}`}>{file.styleTitle}</Link>,
        });
      }
      if (file.error) {
        fields.push({
          key: "Error",
          name: intl.formatMessage(messages.Error),
          value: <div>
            {file.error.split("\n").map((item, key) => {
              return <div key={key}>{item}</div>;
            })}
          </div>,
        });
      }
      return (
          <div>
            <DetailHeader title={file.fileName}/>
            <DetailView fields={fields}/>
          </div>
      );
    } else if (isLoading) {
      return <p><FormattedMessage id="studio.file.detail.loading" defaultMessage="Loading..."/></p>;
    }
    return <p><FormattedMessage id="studio.file.detail.unknown-page-state"
                                defaultMessage="Unknown file detail page state!"/></p>;
  }
}

const mapStateToProps = (state, ownProps) => {
  // either passed as own properties or as a router parameter
  const id = getNumberFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    fileId: id,
  };
};

export const FileDetailPage = connect(mapStateToProps)(WithApi(injectIntl(FileDetailPageComponent)));
