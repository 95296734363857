import * as React from "react";
import {MenuItem, NavDropdown} from "react-bootstrap";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {LinkContainer} from "react-router-bootstrap";

const HELP_MESSAGES = defineMessages({
  help: {
    id: "studio.navbar.help",
    defaultMessage: "Help",
  },
  userGuide: {
    id: "studio.navbar.help.user-guide",
    defaultMessage: "User Guide",
  },
});

class HelpNavDropdownComponent extends React.Component<InjectedIntlProps> {

  render() {
    const {intl} = this.props;
    return (

        <NavDropdown className="user-tour-help-page-nav" eventKey={"help"} id="helpDropDown" title={intl.formatMessage(HELP_MESSAGES.help)}>
          <LinkContainer to="/help"><MenuItem eventKey={"userGuide"} key={"userGuide"}>
            {intl.formatMessage(HELP_MESSAGES.userGuide)}
          </MenuItem></LinkContainer>
          {/*<MenuItem divider></MenuItem>*/}
          {/*<MenuItem eventKey={"userTours"} key={"userTours"}>User Tours</MenuItem>*/}
        </NavDropdown>
    );
  }
}

export const HelpNavDropdown = injectIntl<{}>(connect()(HelpNavDropdownComponent));
