import * as React from "react";
import {ControlLabel, FormControl, FormGroup, ProgressBar} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {LcdIcon} from "../icon/LcdIcon";
import {FileUploadFormState} from "./FileUploadFormState";

interface FileUploadControlsProperties {
  fileUploadFormState: FileUploadFormState;
  updateFileSelection: (files: File[]) => void;
  allowedFileExtension: string;
  allowMultipleFiles: boolean;
}

const FILE_UPLOAD_MESSAGES = defineMessages({
  uploadFinished: {
    id: "studio.file-upload.upload-finished",
    defaultMessage: "Upload finished",
  },
  uploadCanceled: {
    id: "studio.file-upload.upload-canceled",
    defaultMessage: "Upload cancelled",
  },
  uploadFailed: {
    id: "studio.file-upload.upload-failed",
    defaultMessage: "Upload failed",
  },
});

export class FileUploadControlsComponent extends React.Component<FileUploadControlsProperties & InjectedIntlProps, {}> {

  handleFilesSelected = (event) => {
    const fileList: FileList = (event.target as HTMLInputElement).files;
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      files[i] = fileList.item(i);
    }

    this.props.updateFileSelection(files);
  }

  render() {
    const {fileUploadFormState, intl} = this.props;

    if (fileUploadFormState.isUploading) {
      return (
          <FormGroup>
            <h4><FormattedMessage id="studio.file-upload.upload-in-progress" defaultMessage="Upload in progress..." /></h4>
            <ProgressBar now={fileUploadFormState.progress}
                         label={`${fileUploadFormState.progress.toFixed(0)} %`} active/>
          </FormGroup>
      );
    } else if (fileUploadFormState.finished) {
      const status = fileUploadFormState.status;
      const allUploadsSuccessful = !(status?.find((upload) => !upload.success));
      const uploadCancelled = !!(status?.find(
          (upload) => !upload.success && upload.message.indexOf("cancelled") >= 0));

      let statusMessage;
      if (allUploadsSuccessful) {
        statusMessage = intl.formatMessage(FILE_UPLOAD_MESSAGES.uploadFinished);
      } else if (uploadCancelled) {
        statusMessage = intl.formatMessage(FILE_UPLOAD_MESSAGES.uploadCanceled);
      } else {
        statusMessage = intl.formatMessage(FILE_UPLOAD_MESSAGES.uploadFailed);
      }

      return (
          <FormGroup controlId="fileUploadFinished">
            <h4><FormattedMessage id="studio.file-upload.upload" defaultMessage="Upload" /> {statusMessage}</h4>
          </FormGroup>
      );
    } else {
      //In order to replace the ugly browser-specific file chooser button, the label is rendered as a button, and the input itself is hidden.
      //This works because clicking on a label has the same behavior as clicking on the input that is associated with the label.
      //NOTE: Due to the bug in MSEdge the HTML label and input elements must be placed as siblings (versus the input nested in the label).
      return (
          <FormGroup controlId="fileUploadFormPath">
            <ControlLabel className="btn btn-default">
              <LcdIcon icon="plus"/> <FormattedMessage id="studio.file-upload.add-new-files"
                                                                               defaultMessage="Add new files"/>
            </ControlLabel>
            <FormControl style={{display: "none"}}
                         name="file" type="file"
                         accept={this.props.allowedFileExtension}
                         multiple={this.props.allowMultipleFiles}
                         onChange={this.handleFilesSelected}/>
          </FormGroup>
      );
    }
  }
}

export const FileUploadControls = injectIntl(FileUploadControlsComponent);
