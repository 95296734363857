import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Spinner} from "../../common/ui/spinner/Spinner";
import {Logger} from "../../common/util/Logger";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";

interface LogPageState {
  logtext: string;
  busyLoading: boolean;
}

class LogsPageComponent extends React.Component<WithApiProperties, LogPageState> {

  _logger: Logger = Logger.getLogger("settings.LogsPage");

  constructor(props) {
    super(props);
    this.state = {
      logtext: "",
      busyLoading : true,
    };
  }

  componentDidMount() {
    this.props.api.getActuator("logfilesize").then(( size ) => {
      return this.props.api.getActuator("logfile", {
        Range: "bytes=" + (size > 100000 ? (size - 100000) : 0) + "-",
      }).then(( input ) => {
        return this.setState({
          logtext: input,
          busyLoading : false,
        });
      });
    }).catch((error) => {
      this._logger.error("Error occurred while requesting logfile", error);
    });
  }

  render() {
    const content = this.state.busyLoading ?
                      (<div style={{width: "100%", textAlign: "center"}}><Spinner bigStyle={true}/></div>) :
                      (<div className="form-group"><textarea className="form-control" readOnly={true} rows={30} value={this.state.logtext} /></div>);
    return (
        <div>
          <h1><FormattedMessage id="studio.settings.logs.header" defaultMessage="Logs"/></h1>
          <hr/>
          <p>
            <FormattedMessage id="studio.settings.logs.description"
                              defaultMessage="Below, you find the last 100,000 bytes of the log file."/>
          </p>
          <p>
            <FormattedMessage id="studio.settings.logs.description-2"
                              defaultMessage={"By default, the complete log files are located in the logs folder in the installation folder of LuciadFusion. This can be changed by adjusting the {code} property in the application configuration profiles, located in the config folder."}
                              /* tslint:disable-next-line:jsx-use-translation-function */
                              values={{code: <code>logging.file</code>}}/>
          </p>
          {content}
        </div>

    );
  }
}

export const LogsPage = WithApi(LogsPageComponent);
