//selectors are separate here, in order to break cyclic dependency cycle between actions and reducer

import {PagedDataState} from "../common/util/pagination/PaginationUtil";
import {ImportedData, ImportedDataSearchFilter} from "./model";
import {ImportedDataState} from "./reducer";

export const moduleName = "importedData";
const getImportedDataState = (state): ImportedDataState => state[moduleName];

const getReactorDataUpdateState = (state) => {
  const dataState = getImportedDataState(state);
  return dataState.reactorDataUpdates;
};

const getNumberOfNewDataItemsOnServer = (state) => {
  const reactorUpdateState = getReactorDataUpdateState(state);
  return reactorUpdateState.numberOfNewDataItems;
};

const getDataTypes = (state) => getImportedDataState(state).allDataTypes;

const getPagedState = (state): PagedDataState<ImportedData> => {
  return getImportedDataState(state).pages;
};

const getFilter = (state): ImportedDataSearchFilter => {
  const importedDataState = getImportedDataState(state);
  return importedDataState.filter;
};

export const selectors = {
  getFilter,
  getNumberOfNewDataItemsOnServer,
  getDataTypes,
  getPagedState,
};
