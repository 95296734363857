import {Message} from "@stomp/stompjs";
import * as React from "react";
import {Button} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Redirect, withRouter} from "react-router-dom";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailView, Formats} from "../common/ui/detailsection/DetailView";
import {SectionHeader} from "../common/ui/detailsection/SectionHeader";
import {ErrorDisplay} from "../common/ui/errordisplay/ErrorDisplay";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {Previewer} from "../common/ui/riamap/Previewer";
import {UploadOptions} from "../common/ui/upload/UploadOptions";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {getParameterFromOwnProps} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {WithUpdateClient, WithUpdateClientProps} from "../liveupdate/WithUpdateClient";
import * as paths from "../paths";
import {AddToProductButton} from "../products/AddToProductButton";
import {CreateProductButton} from "../products/CreateProductButton";
import {PublishDataInServiceButton} from "../services/CreateServiceButton";
import {DeleteDataButton} from "./DeleteDataButton";
import {ImportedData} from "./model";
import {RefreshDataButton} from "./RefreshDataButton";

interface DataUpdatedMessage {
  oldDataId: string;
  newDataId: string;
}

interface DataDetailPageState {
  data: ImportedData;
  isLoading: boolean;
  success: boolean;
  error: Error;
  deleted: boolean;
}

interface DataDetailPageProps {
  dataId: string;
  showInPreview?: boolean;
}

const DATA_DETAIL_MESSAGES = defineMessages({
  ID: {
    id: "studio.data.detail.id",
    defaultMessage: "Id",
  },
  PublicID: {
    id: "studio.data.detail.publicid",
    defaultMessage: "PublicId",
  },
  Title: {
    id: "studio.data.detail.title",
    defaultMessage: "Title",
  },
  Abstract: {
    id: "studio.data.detail.abstract",
    defaultMessage: "Abstract",
  },
  Keywords: {
    id: "studio.data.detail.keywords",
    defaultMessage: "Keywords",
  },
  Path: {
    id: "studio.data.detail.path",
    defaultMessage: "Path",
  },
  Format: {
    id: "studio.data.detail.format",
    defaultMessage: "Format",
  },
  FileName: {
    id: "studio.data.detail.file-name",
    defaultMessage: "File Name",
  },
  FileSize: {
    id: "studio.data.detail.file-size",
    defaultMessage: "File Size",
  },
  DateCreated: {
    id: "studio.data.detail.date-created",
    defaultMessage: "Date Created",
  },
});

export class DataDetailPageComponent extends React.Component<RouteComponentProps<{ id: string }> & InjectedIntlProps & DataDetailPageProps & WithApiProperties & FileUploadAndInjectedProps & WithUpdateClientProps, DataDetailPageState> {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      success: false,
      error: null,
      deleted: false,
    };
  }

  componentDidMount() {
    const {dataId} = this.props;
    this.getImportedDataById(dataId);
    this.props.subscribe("/imported-data/idChanged", this.handleDataIdUpdated);
  }

  handleDataIdUpdated = (message: Message) => {
    const dataUpdatedMessage: DataUpdatedMessage = JSON.parse(message.body);
    if (this.state.data && this.state.data.id === dataUpdatedMessage.oldDataId) {
      this.props.history.replace("/data/" + encodeURIComponent(dataUpdatedMessage.newDataId));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dataId = nextProps.dataId;
    if (dataId && this.props.dataId !== dataId) {
      this.getImportedDataById(dataId);
    }
  }

  getImportedDataById(id: string) {
    if (id == null) {
      this.updateState({isLoading: false, error: "Incorrect item id: " + id});
    }
    this.updateState({isLoading: true});
    this.props.api.getImportedDataById(id).then(
        (data) => this.updateState({data, isLoading: false, success: true}),
    ).catch(
        (error) => this.updateState({data: null, isLoading: false, error}),
    );
  }

  setDeleted = () =>  {
    this.updateState({deleted: true});
  }

  updateData = (updatedData: ImportedData) => {
    this.updateState({data: updatedData});
  }

  updateState = (updatedPart) => this.setState(Object.assign({}, this.state, updatedPart));

  render() {
    const {isLoading, success, error, data, deleted} = this.state;
    const {api, intl} = this.props;
    if (deleted) {
      return <Redirect to="/data"/>;
    } else if (!isLoading && error) {
      return <ErrorDisplay error={error}/>;
    } else if (!isLoading && success) {
      const {creationTime} = data;
      const masterPane = (
          <div>
            <DetailHeader
                title={data.title}
                buttons={[
                    <ActionBar key="actionbar">
                      <RefreshDataButton key="refresh-data-button" data={data} onRefreshSuccess={this.updateData} onRefreshError={Function.prototype}/>
                      <DeleteDataButton key="delete-data-button" disabled={false} data={[data]} onDeleteSuccess={this.setDeleted} onDeleteError={Function.prototype} detail={true}
                                    onDeleteWarnings={Function.prototype} onCloseWarnings={this.setDeleted}/>
                    </ActionBar>
                ]}
            />
            <ActionBar>
              <PublishDataInServiceButton disabled={false} inputData={[data]}/>
              <CreateProductButton initialData={[data]}/>
              <AddToProductButton initialData={[data]}/>
            </ActionBar>

            <SectionHeader>
              <FormattedMessage id="studio.data.data-detail-page.metadata-header" defaultMessage="Metadata"/>
            </SectionHeader>
            <ActionBar>
              <div>
                <Button onClick={() => {
                  this.props.api.downloadMetadata(data);
                }}>
                  <LcdIcon icon="download-alt"/><FormattedMessage
                    id="studio.data.data-detail-page.download"
                    defaultMessage=" Download"/>
                </Button>
              </div>
              <div>
                <Button onClick={this.props.openUpload}>
                  <LcdIcon icon="import"/> <FormattedMessage
                    id="studio.data.data-detail-page.import"
                    defaultMessage="Import"/>
                </Button>
              </div>
            </ActionBar>
            <DetailView fields={[
              {
                key: "Id",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.ID),
                value: data.id,
              },
              {
                key: "Title",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.Title),
                value: data.title,
              },
              {
                key: "Abstract",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.Abstract),
                value: data.abstractText,
              },
              {
                key: "Keywords",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.Keywords),
                value: data.keywords.join(", "),
              },
              {
                key: "Path",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.Path),
                value: data.filePath,
              },
              {
                key: "Format",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.Format),
                value: data.type,
              },
              {
                key: "File Name",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.FileName),
                value: data.fileMetadata.name,
              },
              {
                key: "File Size",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.FileSize),
                value: data.fileMetadata.size,
                format: Formats.FILESIZE,
              },
              {
                key: "Date Created",
                name: intl.formatMessage(DATA_DETAIL_MESSAGES.DateCreated),
                value: creationTime,
                format: Formats.DATETIME,
              },
            ]}/>
          </div>
      );
      const getPreviewMetadataFunction = (item) => api.getImportedDataPreviewMetadata(item.id);
      const detailPane = (
          <Previewer wmsBaseUrl={paths.PREVIEW_DATA_PATH}
                     dataToVisualize={this.state.data ? [this.state.data] : []}
                     shouldFit={true}
                     getPreviewMetadataFunction={getPreviewMetadataFunction}
          />
      );
      return <MasterDetailLayout masterPane={masterPane} detailPane={detailPane} evenSplit={true}/>;
    } else if (isLoading) {
      return <p><FormattedMessage id="studio.data.data-detail-page.loading" defaultMessage="Loading..."/></p>;
    }
    return <p><FormattedMessage id="studio.data.data-detail-page.unknown-state"
                                defaultMessage="Unknown data detail page state!"/></p>;
  }
}

const mapStateToProps = (state, ownProps) => {
  // either passed as own properties or as a router parameter
  const id = getParameterFromOwnProps(ownProps, "dataId"); //maybe injected by withRouter
  return {
    dataId: id,
    createFormData: (uploadFiles: File[]) => {
      const data = new FormData();
      data.append("importedDataId", id);
      data.append("metadataFile", uploadFiles[0]);
      return data;
    },
  };
};

const metadataUploadOptions: UploadOptions = {
  allowedFileExtension: ".xml",
  allowMultipleFiles: false,
  uploadUri: "/upload/metadata",
};

const DataDetailPageComponentWithFileUpload = WithFileUpload(metadataUploadOptions)(
    injectIntl(DataDetailPageComponent));

export const DataDetailPage = connect(mapStateToProps)(
    withRouter(WithApi(WithUpdateClient(DataDetailPageComponentWithFileUpload))));
