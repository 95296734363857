//ALWAYS MAKE SURE THESE VALUES ARE IN SYNC WITH THE FUSION RELEASE PATH LAYOUT

//The current assumption is that the paths are organized as follows. All paths are relative to index.html:
// ..                          (BASE_PATH)
//      /studio                (STUDIO_PATH)
//          /service           (STUDIO_SERVICE_PATH)
//              /paths         (STUDIO_SERVICE_REST_API_PATH)
//              /notifications (STUDIO_SERVICE_NOTIFICATION_PATH)
//              /login         (STUDIO_SERVICE_LOGIN_PATH)
//              /logout        (STUDIO_SERVICE_LOGOUT_PATH)
//      /ogc                   (OGC_PATH)

//The constants are relative paths (relative to index.html).
//For absolute URLs, use the getAbsoluteUrl utility functions, which does an educated guess using the current location.

export const BASE_PATH = ".."; //relative to index.html, make sure that getAbsoluteUrl still works!

export const STUDIO_PATH = "/studio"; //KEEP IN SYNC WITH WebApp's fusion.studio.webApp.contextPath PROPERTY!!

const SERVICE_PATH_FROM_STUDIO = "/service";
export const STUDIO_SERVICE_PATH = BASE_PATH + STUDIO_PATH + SERVICE_PATH_FROM_STUDIO;
const STUDIO_SERVICE_PATH_FROM_BASE = STUDIO_PATH + SERVICE_PATH_FROM_STUDIO;
export const STUDIO_SERVICE_API_PATH = STUDIO_SERVICE_PATH + "/api";
export const STUDIO_API_PATH = BASE_PATH + "/api";
export const STUDIO_SERVICE_LOGIN_PATH = STUDIO_SERVICE_PATH + "/login";
export const STUDIO_SERVICE_LOGOUT_PATH = STUDIO_SERVICE_PATH + "/logout";
export const STUDIO_SERVICE_NOTIFICATION_PATH_FROM_BASE = STUDIO_SERVICE_PATH_FROM_BASE + "/notifications";

export const IMPORTEDDATA_PATH = "/imported-data";
export const STYLE_PATH = "/style";
export const STYLES_PATH = "/styles";
export const PRODUCT_PATH = "/product";
export const PRODUCTS_PATH = "/products";
export const SERVICE_PATH = "/services";
export const STYLEDDATA_PATH = "/styled-data";
export const STYLEDDATASTUDIO_PATH = "/styled-data-studio";
export const CRAWLJOBS_PATH = "/crawl-jobs";
export const PREPROCESSJOBS_PATH = "/preprocess-jobs";
export const PREVIEW_PATH = "/preview";
export const FILEINFO_PATH = "/file-info";

export const OGC_PATH = "/ogc";
export const PREVIEW_DATA_PATH = OGC_PATH + "/wms/preview_data_";
export const PREVIEW_BACKGROUND_DATA_PATH = PREVIEW_DATA_PATH + "background";
export const PREVIEW_STYLE_PATH = OGC_PATH + "/wms/preview_style_";
export const PREVIEW_PRODUCT_PATH = OGC_PATH + "/wms/preview_product_";

export const TRANSLATIONS_PATH = STUDIO_PATH + "/translations";
export const TRANSLATIONS_LOCALE_DATA_PATH = STUDIO_PATH + "/intl/locale-data";

export const MAPPENT_TOKEN_PATH = "/token";

export const getAbsoluteBaseUrl = (protocol = window.location.protocol) => {
  const basePath = getContextPath();
  return `${protocol}//${window.location.hostname}:${window.location.port}${basePath}`;
};

/**
 * Returns the context path of the application.
 */
export const getContextPath = () => {
  //this assumes the BASE_URL is accessible as ".." (relative to index.html)
  const path = window.location.pathname; // /app/foo/bar/studio/#/services/1
  return path.slice(0, path.indexOf(STUDIO_PATH)); //strip everything after /studio
};

/**
 * Returns the page url relative to context path.
 */
export const getPageURL = () => {
  const path = window.location.pathname;
  const contextPath = getContextPath();
  return path.slice(path.indexOf(contextPath) + contextPath.length, path.length);
};
