import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createAddButtonComponent} from "../common/ui/AddButton";
import {actions} from "./actions";
import {ImportedData} from "./model";

const AddButtonComponent = createAddButtonComponent<ImportedData[]>();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (filePath: string) => {
    return dispatch(actions.addData(filePath));
  },
});

interface AddDataButtonDispatchProps {
  onAdd: (filePath: string) => Promise<ImportedData[]>;
}

interface AddDataButtonProps {
  onAddSuccess: () => void;
}

const DATA_MESSAGES = defineMessages({
  buttonTitle: {
    id: "studio.data.add-data-button.button-title",
    defaultMessage: "Add Data",
  },
  chooseFile: {
    id: "studio.data.add-data-button.choose-file",
    defaultMessage: "Choose File",
  },
  selectData: {
    id: "studio.data.add-data-button.select-data",
    defaultMessage: "Select Data",
  },
  helperText: {
    id: "studio.data.add-data-button.helper-text",
    defaultMessage: "Enter the file path for the data you want to add, e.g Data/SHP/world.shp",
  },
  executingMessage: {
    id: "studio.data.add-data-button.executing-message",
    defaultMessage: "Adding data...",
  },
});

class AddDataButtonComponent extends React.Component<AddDataButtonProps & InjectedIntlProps & AddDataButtonDispatchProps> {
  render() {
    const {intl, onAdd, onAddSuccess} = this.props;
    return <AddButtonComponent
        buttonTitle={intl.formatMessage(DATA_MESSAGES.buttonTitle)}
        confirmButtonTitle={intl.formatMessage(DATA_MESSAGES.buttonTitle)}
        modalTitle={intl.formatMessage(DATA_MESSAGES.buttonTitle)}
        chooseFileTitle={intl.formatMessage(DATA_MESSAGES.selectData)}
        chooseFileButtonTitle={intl.formatMessage(DATA_MESSAGES.chooseFile)}
        addHelperText={intl.formatMessage(DATA_MESSAGES.helperText)}
        executingMessage={intl.formatMessage(DATA_MESSAGES.executingMessage)}
        onAdd={onAdd}
        onAddSuccess={onAddSuccess}
        intl={intl}/>;
  }
}

export const AddDataButton = injectIntl(connect(null, mapDispatchToProps)(AddDataButtonComponent));
