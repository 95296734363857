import * as React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  Tab,
  Tabs,
} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, Dispatch} from "redux";
import {
  Fields,
  FormErrors,
  getFormSyncErrors,
  hasSubmitSucceeded,
  InjectedFormProps,
  isPristine,
  isSubmitting,
  reduxForm,
  SubmissionError,
  submit,
  WrappedFieldProps,
} from "redux-form";
import {createCloseButton} from "../common/ui/FormButtons";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {productValidation} from "../common/ui/validation/ProductValidation";
import {ServiceTypeValidation, ValidationMessageSeverity} from "../common/ui/validation/ServiceTypeValidation";
import {moveItem, removeItem} from "../common/util/ArrayUtil";
import {Logger} from "../common/util/Logger";
import {calculateDimensionBasedOnRowsBeforeScroll, isEmpty} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {PendingProductForService, Product, ProductType, StyledData} from "../products/model";
import {ChooseFolderDialog} from "../settings/dataroots/ChooseFolderDialog";
import {DataRoot} from "../settings/dataroots/model";
import {actions} from "./actions";
import {
  MeshCompression,
  PointCloudCompression,
  Service,
  ServiceStatus,
  ServiceType,
  ServiceTypeDetails,
  supportsAccessConstraints,
  supportsContactInfo,
  toServiceLink
} from "./model";
import {SERVICE_PRODUCT_LIST_ROW_HEIGHT, ServiceProductList} from "./ServiceProductList";
import {addValidationToProducts} from "../common/util/ValidationUtil";
import {DataCategory, ImportedData} from "../data/model";
import {selectors as defaultMetadataSelectors} from "../common/defaultMetadata/selectors";
import {actions as defaultMetadataActions} from "../common/defaultMetadata/actions";
import {DefaultMetadata} from "../common/defaultMetadata/model";
import InputGroup = require("react-bootstrap/lib/InputGroup");
import InputGroupAddon = require("react-bootstrap/lib/InputGroupAddon");
import InputGroupButton = require("react-bootstrap/lib/InputGroupButton");

const SERVICE_FORM_MESSAGES = defineMessages({
  loadURIError: {
    id: "studio.create-service-form.uri-error",
    defaultMessage: "Error occurred while loading service uri",
  },
  serviceTypeError: {
    id: "studio.create-service-form.service-type-error",
    defaultMessage: "Error occurred while retrieving service types",
  },
  uniqueNameError: {
    id: "studio.create-service-form.unique-name-error",
    defaultMessage: "Error occurred while determining unique name for service",
  },
  similarNameError: {
    id: "studio.create-service-form.similar-name-error",
    defaultMessage: "Error fetching services with similar names",
  },
  chooseOutputDirectory: {
    id: "studio.create-service-form.choose-output-directory",
    defaultMessage: "Choose Output Directory",
  },
  chooseLocation: {
    id: "studio.create-service-form.choose-location",
    defaultMessage: "Choose Location",
  },
  submitFailed: {
    id: "studio.create-service-form.submit-failed",
    defaultMessage: "Service creation failed:",
  },
  submitting: {
    id: "studio.create-service-form.submitting",
    defaultMessage: "Creating service...",
  },
  metadataLabel: {id: "studio.services.service-detail.metadata-header", defaultMessage: "Metadata"},
  contactInfoLabel: {id: "studio.services.service-detail.contact-info-header", defaultMessage: "Contact Information"},
  productsLabel: {id: "studio.services.service-detail.products-header", defaultMessage: "Products"},
  productsCountError: {
    id: "studio.create-service-form.service-products-count-error",
    defaultMessage: "{type} services can only have {count} product(s).",
  },
  unknownTypeError: {
    id: "studio.create-service-form.unknown-service-type-error",
    defaultMessage: "Couldn't find service type {type}",
  },
  sameAsSource: {id: "studio.create-service-form.same-as-source", defaultMessage: "Same as source"},
});

// strip spaces, convert to lowercase and strip symbols
const toSafeName = (text) => text.replace(/\s/g, "_").toLowerCase().replace(/[^\s\w\-_]/g, "");
const canAutoFill = (field) => !field.meta.touched;

interface UrlComponentProps {
  serviceType: ServiceType;
  serviceName: string;
}

interface UrlComponentState {
  serviceUrl: string;
}

class UrlComponent extends React.Component<UrlComponentProps & WithApiProperties & InjectedIntlProps, UrlComponentState> {

  _logger: Logger = Logger.getLogger("services.CreateServiceForm.UrlComponent");

  _mounted: boolean;

  constructor(props) {
    super(props);
    this.state = {serviceUrl: ""};
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    const prevNormalizedServiceName = toSafeName(prevProps.serviceName);
    const nextNormalizedServiceName = toSafeName(this.props.serviceName);

    const prevServiceType = prevProps.serviceType;
    const nextServiceType = this.props.serviceType;

    const normalizedServiceNameChanged = prevNormalizedServiceName !== nextNormalizedServiceName;
    const normalizedServiceNameIsEmpty = !nextNormalizedServiceName || nextNormalizedServiceName === '';
    const serviceTypeChanged = prevServiceType !== nextServiceType;

    if ((serviceTypeChanged || normalizedServiceNameChanged) && !normalizedServiceNameIsEmpty) {
      this.props.api.getServiceUri(nextServiceType.toLowerCase(), nextNormalizedServiceName)
          .then((serviceUrl) => {
            if (this._mounted) {
              this.setState({serviceUrl});
            }
          }).catch((error) => {
        this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.loadURIError), error);
      });
    } else {
      if (!this._mounted) {
        this.setState({serviceUrl: ""});
      }
    }
  }

  render() {
    return <ControlLabel><strong>{this.state.serviceUrl}</strong></ControlLabel>;
  }
}

export interface CreateServiceFormFieldsData {
  serviceTitle: string;
  serviceName: string;
  serviceType: ServiceType;
  serviceMeshCompression?: MeshCompression;
  servicePointCloudCompression?: PointCloudCompression;
  serviceAbstract: string;
  serviceKeywords: string;
  serviceAccessConstraint: string;
  serviceContactIndividualName: string;
  serviceContactOrganizationName: string;
  serviceContactPosition: string;
  serviceContactDeliveryPoint: string;
  serviceContactCity: string;
  serviceContactAdministrativeArea: string;
  serviceContactPostCode: string;
  serviceContactCountry: string;
  serviceContactFax: string;
  serviceContactPhone: string;
  serviceContactEmail: string;
  servicePreprocessingPath: string;
  immediatelyStartService: boolean;
  serviceProducts: Product[];
}

export interface CreateServiceFormFieldsDataProps {
  serviceTitle: WrappedFieldProps;
  serviceName: WrappedFieldProps;
  serviceType: WrappedFieldProps;
  serviceMeshCompression: WrappedFieldProps;
  servicePointCloudCompression: WrappedFieldProps;
  serviceAbstract: WrappedFieldProps;
  serviceKeywords: WrappedFieldProps;
  serviceAccessConstraint: WrappedFieldProps;
  serviceContactIndividualName: WrappedFieldProps;
  serviceContactOrganizationName: WrappedFieldProps;
  serviceContactPosition: WrappedFieldProps;
  serviceContactDeliveryPoint: WrappedFieldProps;
  serviceContactCity: WrappedFieldProps;
  serviceContactAdministrativeArea: WrappedFieldProps;
  serviceContactPostCode: WrappedFieldProps;
  serviceContactCountry: WrappedFieldProps;
  serviceContactFax: WrappedFieldProps;
  serviceContactPhone: WrappedFieldProps;
  serviceContactEmail: WrappedFieldProps;
  servicePreprocessingPath: WrappedFieldProps;
  immediatelyStartService: WrappedFieldProps;
  serviceProducts: WrappedFieldProps;
}

interface CreateServiceFormFieldsOwnProps {
  inputProducts?: PendingProductForService[];
  initServiceTypeValidation?: ServiceTypeValidation;
  defaultMetadata: DefaultMetadata;
  loadDefaultMetadata: () => Promise<void>;
}

export type CreateServiceFormFieldsProps =
    CreateServiceFormFieldsOwnProps
    & CreateServiceFormFieldsDataProps
    & InjectedIntlProps
    & WithApiProperties;


interface CreateServiceFormFieldsState {
  dynamicServiceTypeDetails: ServiceTypeDetails[];
  serviceUrl?: string;
  renderServicePreprocessingPathField: boolean;
  showChooseDataRootDialog: boolean;
  preprocessingPath: string;
  serviceTypeValidation: ServiceTypeValidation;
  selectedServiceType: ServiceType;
  products: Product[];
  renderMeshCompression: boolean;
  renderPointCloudCompression: boolean;
  renderSameAsSourceCompression: boolean;
  defaultMetadata?: DefaultMetadata;
  activeTab: TabKey;
}

const FIELD_SERVICE_TITLE = "serviceTitle";
const FIELD_SERVICE_ABSTRACT = "serviceAbstract";
const FIELD_SERVICE_KEYWORDS = "serviceKeywords";
const FIELD_SERVICE_START = "immediatelyStartService";
const FIELD_SERVICE_NAME = "serviceName";
const FIELD_SERVICE_TYPE = "serviceType";
const FIELD_SERVICE_ACCESS_CONSTRAINT = "serviceAccessConstraint";
const FIELD_SERVICE_PRODUCTS = "serviceProducts";
const FIELD_SERVICE_PREPROCESSING_PATH = "servicePreprocessingPath";
const FIELD_SERVICE_MESH_COMPRESSION = "serviceMeshCompression";
const FIELD_SERVICE_POINT_CLOUD_COMPRESSION = "servicePointCloudCompression";
const FIELD_SERVICE_CONTACT_INDIVIDUAL_NAME = "serviceContactIndividualName";
const FIELD_SERVICE_CONTACT_ORGANIZATION_NAME = "serviceContactOrganizationName";
const FIELD_SERVICE_CONTACT_POSITION = "serviceContactPosition";
const FIELD_SERVICE_CONTACT_DELIVERY_POINT = "serviceContactDeliveryPoint";
const FIELD_SERVICE_CONTACT_CITY = "serviceContactCity";
const FIELD_SERVICE_CONTACT_ADMINISTRATIVE_AREA = "serviceContactAdministrativeArea";
const FIELD_SERVICE_CONTACT_POST_CODE = "serviceContactPostCode";
const FIELD_SERVICE_CONTACT_COUNTRY = "serviceContactCountry";
const FIELD_SERVICE_CONTACT_FAX = "serviceContactFax";
const FIELD_SERVICE_CONTACT_PHONE = "serviceContactPhone";
const FIELD_SERVICE_CONTACT_EMAIL = "serviceContactEmail";

const LABEL_COL_WIDTH = 2;

const DEFAULT_SERVICE_TYPE_DETAILS: ServiceTypeDetails = {
  serviceType: ServiceType.WMS,
  hasCapabilities: true,
  needsPreprocessing: false,
};

const OFFLINE_METADATA = {
  responsibleParty: {
    address: {},
    email: [],
  },
} as DefaultMetadata;

enum TabKey {
  METADATA = "metadata",
  CONTACT_INFO = "contactInfo",
  PRODUCTS = "products",
}

class CreateServiceFormFieldsComponent extends React.Component<CreateServiceFormFieldsProps & InjectedFormProps<CreateServiceFormFieldsData, CreateServiceFormFieldsProps>, CreateServiceFormFieldsState> {

  _logger: Logger = Logger.getLogger("services.CreateServiceForm.CreateServiceFormReactComponent");
  _defaultPreprocessingPath = null;

  constructor(props) {
    super(props);
    this.state = {
      dynamicServiceTypeDetails: [DEFAULT_SERVICE_TYPE_DETAILS],
      renderServicePreprocessingPathField: false,
      showChooseDataRootDialog: false,
      preprocessingPath: null,
      serviceTypeValidation: props.initServiceTypeValidation,
      products: [],
      selectedServiceType: DEFAULT_SERVICE_TYPE_DETAILS.serviceType,
      renderMeshCompression: false,
      renderPointCloudCompression: false,
      renderSameAsSourceCompression: false,
      activeTab: TabKey.METADATA,
    };
  }

  componentDidMount() {
    const {inputProducts = []} = this.props;

    if (inputProducts) {
      inputProducts.forEach(
          (inputProduct) => inputProduct.pendingServiceType = DEFAULT_SERVICE_TYPE_DETAILS.serviceType);
    }

    this.setState({products: inputProducts});

    // fetch all available service types
    this.props.api.getEnabledServiceTypes()
        .then((serviceTypeDetails) => this.setState({
          dynamicServiceTypeDetails: serviceTypeDetails
              .sort((type1, type2) => type1.serviceType.localeCompare(type2.serviceType))
        }))
        .catch((error) => {
          this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.serviceTypeError), error);
        });


    this.props.loadDefaultMetadata().then(() => {
      let {defaultMetadata} = this.props;
      this.setState({defaultMetadata: defaultMetadata}, () => {
        const {defaultMetadata} = this.state;
        const {responsibleParty} = defaultMetadata;
        const {address} = responsibleParty;

        const {
          serviceAbstract,
          serviceAccessConstraint,
          serviceContactIndividualName,
          serviceContactOrganizationName,
          serviceContactPosition,
          serviceContactPhone,
          serviceContactFax,
          serviceContactEmail,
          serviceContactDeliveryPoint,
          serviceContactCity,
          serviceContactPostCode,
          serviceContactAdministrativeArea,
          serviceContactCountry,
        } = this.props;

        if (defaultMetadata.loadedSuccessfully) {
          if (serviceAbstract.meta.pristine && defaultMetadata.abstractText) {
            this.props.change(FIELD_SERVICE_ABSTRACT, defaultMetadata.abstractText);
          }
          if (serviceAccessConstraint.meta.pristine && defaultMetadata.accessConstraint) {
            this.props.change(FIELD_SERVICE_ACCESS_CONSTRAINT, defaultMetadata.accessConstraint);
          }
          if (serviceContactIndividualName.meta.pristine && responsibleParty.individualName) {
            this.props.change(FIELD_SERVICE_CONTACT_INDIVIDUAL_NAME, responsibleParty.individualName);
          }
          if (serviceContactOrganizationName.meta.pristine && responsibleParty.organizationName) {
            this.props.change(FIELD_SERVICE_CONTACT_ORGANIZATION_NAME, responsibleParty.organizationName);
          }
          if (serviceContactPosition.meta.pristine && responsibleParty.position) {
            this.props.change(FIELD_SERVICE_CONTACT_POSITION, responsibleParty.position);
          }
          if (serviceContactPhone.meta.pristine && responsibleParty.phone) {
            this.props.change(FIELD_SERVICE_CONTACT_PHONE, responsibleParty.phone);
          }
          if (serviceContactFax.meta.pristine && responsibleParty.fax) {
            this.props.change(FIELD_SERVICE_CONTACT_FAX, responsibleParty.fax);
          }
          if (serviceContactEmail.meta.pristine && responsibleParty.email && responsibleParty.email.length > 0) {
            this.props.change(FIELD_SERVICE_CONTACT_EMAIL, responsibleParty.email[0]);
          }
          if (serviceContactDeliveryPoint.meta.pristine && address.deliveryPoint) {
            this.props.change(FIELD_SERVICE_CONTACT_DELIVERY_POINT, address.deliveryPoint);
          }
          if (serviceContactCity.meta.pristine && address.city) {
            this.props.change(FIELD_SERVICE_CONTACT_CITY, address.city);
          }
          if (serviceContactPostCode.meta.pristine && address.postCode) {
            this.props.change(FIELD_SERVICE_CONTACT_POST_CODE, address.postCode);
          }
          if (serviceContactAdministrativeArea.meta.pristine && address.administrativeArea) {
            this.props.change(FIELD_SERVICE_CONTACT_ADMINISTRATIVE_AREA, address.administrativeArea);
          }
          if (serviceContactCountry.meta.pristine && address.country) {
            this.props.change(FIELD_SERVICE_CONTACT_COUNTRY, address.country);
          }
        }

        if (this.state.products && this.state.products[0]) {
          // default service title based on input products
          this.getUniqueName(this.state.products[0].title).catch((error) => {
            this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.uniqueNameError));
          });
          this.configureCompressionState();
        }
      });
    });
  }

  getUniqueName = (baseName: string) =>
      this.props.api.listServices({anyText: toSafeName(baseName) + "*"}).then(
          (servicesWithName) => {
            const defaultServiceTitle = servicesWithName.length ?
                                        baseName + "-" + (servicesWithName.length + 1) :
                                        baseName;
            this.props.autofill(FIELD_SERVICE_TITLE, defaultServiceTitle);
            this.props.autofill(FIELD_SERVICE_NAME, toSafeName(defaultServiceTitle));
          },
      ).catch((error) => {
        this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.similarNameError), error);
      })

  configureCompressionState = () => {
    if (this.state.selectedServiceType === ServiceType.OGC3DTiles.toString() && this.state.products[0] &&
        // LF-2037 product type is always mixed for 3d tiles data with external tile sets
        (this.state.products[0].type === ProductType.MESH ||
         this.state.products[0].type === ProductType.POINT_CLOUD ||
         this.state.products[0].type === ProductType.MIXED)) {
      this.setState({
        renderMeshCompression: this.state.products[0].type === ProductType.MESH || this.state.products[0].type ===
                               ProductType.MIXED,
        renderPointCloudCompression: this.state.products[0].type === ProductType.POINT_CLOUD ||
                                     this.state.products[0].type === ProductType.MIXED,
      });
      this.props.api.loadProductContents(this.state.products[0].id).then((contents: StyledData[]) => {
        this.props.api.getImportedDataById(contents[0].id).then((data: ImportedData) => {
          if (data.categories.indexOf(DataCategory.MESH) !== -1 ||
              data.categories.indexOf(DataCategory.POINT_CLOUD) !== -1) {
            // only show 'Same As Source' mesh compression option for 3D Tiles data
            if (data.type.toLocaleLowerCase() === "3d tiles") {
              // use 'Same as source' (ie. null) by default for 3D tiles data
              this.setState({
                renderPointCloudCompression: false,//V220-868: Point cloud tilesets cannot be repreprocessed yet
                renderSameAsSourceCompression: true
              });
              // Beware, this call should be after the setState call, otherwise react doesn't re-render the component.
              this.props.change(FIELD_SERVICE_MESH_COMPRESSION, null);
              this.props.change(FIELD_SERVICE_POINT_CLOUD_COMPRESSION, null);
            } else {
              this.setState({
                renderSameAsSourceCompression: false
              });
            }
          } else {
            // Only render MESH or POINT CLOUD compression for MESH or POINT CLOUD data
            this.setState({
              renderMeshCompression: false,
              renderPointCloudCompression: false,
              renderSameAsSourceCompression: false
            });
          }
        })
      });
    } else {
      // Only render MESH compression for OGC 3D tiles services
      this.setState({
        renderMeshCompression: false,
        renderPointCloudCompression: false,
        renderSameAsSourceCompression: false
      });
    }
  }

  handleTitleChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceTitle.input.onChange(event);

    if (canAutoFill(fields[FIELD_SERVICE_NAME])) {
      const serviceTitle = event.target.value;
      const derivedServiceName = toSafeName(serviceTitle);
      this.props.change(FIELD_SERVICE_NAME, derivedServiceName);
      this.updatePreprocessingPath(derivedServiceName);
    }
  }

  handleServiceTypeChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields[FIELD_SERVICE_TYPE].input.onChange(event);

    const serviceType = event.target.value;
    this.setState({selectedServiceType: serviceType}, () => this.configureCompressionState());

    if (fields[FIELD_SERVICE_PRODUCTS].input.value) {
      fields[FIELD_SERVICE_PRODUCTS].input.value.forEach(
          ((inputProduct) => inputProduct.pendingServiceType = serviceType));
    }

    const serviceConfiguration = this.state.dynamicServiceTypeDetails.find((aServiceConfiguration) => {
      return aServiceConfiguration.serviceType === serviceType;
    });

    if (serviceConfiguration && serviceConfiguration.needsPreprocessing) {
      if (!this._defaultPreprocessingPath) {
        // Makes a request with an empty service name to fetch the default preprocessing path
        this.props.api.getPreprocessingPath("").then((preprocessingPath) => {
          this._defaultPreprocessingPath = preprocessingPath;
        }).catch((error) => {
          this._logger.error("Error occurred while loading preprocessing path", error);
        });
      }

      const serviceName = fields[FIELD_SERVICE_NAME].input.value;
      this.props.api.getPreprocessingPath(serviceName).then((preprocessingPath) => {
        this.setState({
          renderServicePreprocessingPathField: true,
          preprocessingPath,
        });

        // Beware, this call should be after the setState call, otherwise react doesn't re-render the component.
        this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, preprocessingPath);
      }).catch((error) => {
        this._logger.error("Error occurred while loading preprocessing path", error);

        this.resetPreprocessingPath();
      });
    } else {
      this.resetPreprocessingPath();
    }
  }

  handleMeshCompressionChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceMeshCompression.input.onChange(event);
    if (!event.target.value || event.target.value === '') {
      this.props.change(FIELD_SERVICE_MESH_COMPRESSION, null);
    } else {
      this.props.change(FIELD_SERVICE_MESH_COMPRESSION, event.target.value);
    }
  }

  handlePointCloudCompressionChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.servicePointCloudCompression.input.onChange(event);
    if (!event.target.value || event.target.value === '') {
      this.props.change(FIELD_SERVICE_POINT_CLOUD_COMPRESSION, null);
    } else {
      this.props.change(FIELD_SERVICE_POINT_CLOUD_COMPRESSION, event.target.value);
    }
  }

  handleServiceNameChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceName.input.onChange(event);
    const serviceName = toSafeName(event.target.value);
    this.updatePreprocessingPath(serviceName);
  }

  updatePreprocessingPath = (serviceName) => {
    if (this.state.renderServicePreprocessingPathField) {
      this.props.api.getPreprocessingPath(serviceName).then((preprocessingPath) => {
        this.setState({
          preprocessingPath,
        });
        this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, preprocessingPath);
      }).catch((error) => {
        this._logger.error("Error occurred while loading preprocessing path", error);
        this.resetPreprocessingPath();
      });
    }
  }

  resetPreprocessingPath = () => {
    this.setState({
      preprocessingPath: null,
      renderServicePreprocessingPathField: false,
    });

    this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, "");
  }

  showChooseDataRootDialog = () => {
    this.setState({showChooseDataRootDialog: true});
  }

  renderServiceTypes = () => this.state.dynamicServiceTypeDetails.map((serviceConfiguration) => {
    return <option key={serviceConfiguration.serviceType} value={serviceConfiguration.serviceType}>
      {serviceConfiguration.serviceType.toUpperCase()}
    </option>;
  })

  renderMeshCompressionTypes = () => Object.keys(MeshCompression).map(meshCompressionType => {
    return <option key={meshCompressionType} value={MeshCompression[meshCompressionType]}>
      {MeshCompression[meshCompressionType]}
    </option>;
  })

  renderPointCloudCompressionTypes = () => Object.keys(PointCloudCompression).map(pointCloudCompressionType => {
    return <option key={pointCloudCompressionType} value={PointCloudCompression[pointCloudCompressionType]}>
      {PointCloudCompression[pointCloudCompressionType]}
    </option>;
  })

  productTabClassName = (serviceType) => {
    const {serviceTypeValidation} = this.state;
    if (!serviceTypeValidation || !serviceType) {
      return null;
    }
    const serviceValidation = serviceTypeValidation.serviceTypes.find(validation => validation.name === serviceType);

    const errorCount = serviceValidation.validationMessages.filter(
        message => message.severity === ValidationMessageSeverity.ERROR).length;
    if (errorCount > 0) {
      return "validation-alert validation-error";
    }

    const warningCount = serviceValidation.validationMessages.filter(
        message => message.severity === ValidationMessageSeverity.WARNING).length;
    if (warningCount > 0) {
      return "validation-alert validation-warning";
    }
  }

  closeChooseDataRootDialog = () => {
    this.setState({showChooseDataRootDialog: false});
  }

  handleChooseDataRoot = (dataRoot: DataRoot) => {
    const dataRootPath = dataRoot.rootPath;
    this.setState({preprocessingPath: dataRootPath});
    this.props.autofill(FIELD_SERVICE_PREPROCESSING_PATH, dataRootPath);
    return Promise.resolve();
  }

  handleTabSelected = (tabKey) => {
    this.setState({
      activeTab: tabKey,
    });
  }

  handleContactInfoPanelClicked = () => {
    this.setState({
      activeTab: TabKey.CONTACT_INFO,
    });
  }

  render = () => {
    const {
      renderMeshCompression,
      renderPointCloudCompression,
      renderSameAsSourceCompression,
      renderServicePreprocessingPathField,
      preprocessingPath,
      showChooseDataRootDialog,
    } = this.state;

    const {
      intl,
      serviceTitle,
      serviceAbstract,
      serviceKeywords,
      immediatelyStartService,
      serviceName,
      serviceType,
      serviceProducts,
      serviceMeshCompression,
      servicePointCloudCompression,
      serviceAccessConstraint,
    } = this.props;

    const autoFillClass = serviceName.meta.autofilled ? "autofill" : "";

    const serviceProductsInput = serviceProducts.input;
    const serviceTypeInput = serviceType.input;
    const serviceNameInput = serviceName.input;
    const serviceMeshCompressionInput = serviceMeshCompression.input;
    const servicePointCloudCompressionInput = servicePointCloudCompression.input;

    return (
        <Tabs id="ServiceDetailTabs" bsStyle="pills" justified={false} onSelect={this.handleTabSelected.bind(this)}
              animation={true} className={"tabs"} activeKey={this.state.activeTab}>
          <Tab eventKey={TabKey.METADATA} title={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.metadataLabel)}>
            <div className="form-tab-content">
              <FormGroup controlId={serviceTitle.input.name}
                         validationState={serviceTitle.meta.error ? "error" : null}>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-title"
                                                  defaultMessage="Service title"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl type="text" {...serviceTitle.input}
                               onChange={this.handleTitleChange.bind(this, this.props)}/>
                  {serviceName.meta.error && <HelpBlock>{serviceName.meta.error}</HelpBlock>}
                </Col>
              </FormGroup>
              <FormGroup controlId={serviceTypeInput.name}>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-type"
                                                  defaultMessage="Service type"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl componentClass="select" {...serviceTypeInput}
                               onChange={this.handleServiceTypeChange.bind(this, this.props)}>
                    {this.renderServiceTypes()}
                  </FormControl>
                </Col>
              </FormGroup>
              {serviceTypeInput.value === ServiceType.LTS.toString() ?
               <FormGroup controlId={serviceTypeInput.name}>
                 <Col sm={LABEL_COL_WIDTH}/>
                 <Col sm={12 - LABEL_COL_WIDTH}>
                   <small><strong><FormattedMessage id="studio.services.create-service-form.note"
                                                    defaultMessage="Note"/>&#58;&nbsp;</strong>
                     <FormattedMessage id="studio.services.create-service-form.lts-note"
                                       defaultMessage="LTS services will only start if all products contain either a Fusion coverage or elevation data"/>
                   </small>
                 </Col>
               </FormGroup> : null
              }
              <FormGroup controlId={serviceName.input.name} validationState={serviceName.meta.error ? "error" : null}>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-name"
                                                  defaultMessage="Service name"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl type="text" {...serviceName.input} className={autoFillClass}
                               onChange={this.handleServiceNameChange.bind(this, this.props)}/>
                  {serviceName.meta.error && <HelpBlock>{serviceName.meta.error}</HelpBlock>}
                </Col>
              </FormGroup>
              {renderMeshCompression ?
               <FormGroup controlId={serviceMeshCompressionInput.name}>
                 <Col sm={LABEL_COL_WIDTH}>
                   <ControlLabel><FormattedMessage id="studio.services.create-service-form.mesh-compression"
                                                   defaultMessage="Mesh Compression"/></ControlLabel>
                 </Col>
                 <Col sm={12 - LABEL_COL_WIDTH}>
                   <FormControl componentClass="select" {...serviceMeshCompressionInput}
                                onChange={this.handleMeshCompressionChange.bind(this, this.props)}>
                     {renderSameAsSourceCompression ?
                      <option key="Same as source" value=''>
                        {this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.sameAsSource)}
                      </option> : null
                     }
                     {this.renderMeshCompressionTypes()}
                   </FormControl>
                 </Col>
                 <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                   <HelpBlock>
                     <FormattedMessage
                         id="studio.create-service-form.mesh-compression-description"
                         defaultMessage="Mesh compression reduces content size and loading times, but requires a compatible client."
                     />
                   </HelpBlock>
                 </Col>
               </FormGroup> : null
              }
              {renderPointCloudCompression ?
               <FormGroup controlId={servicePointCloudCompressionInput.name}>
                 <Col sm={LABEL_COL_WIDTH}>
                   <ControlLabel><FormattedMessage id="studio.services.create-service-form.point-cloud-compression"
                                                   defaultMessage="Point cloud Compression"/></ControlLabel>
                 </Col>
                 <Col sm={12 - LABEL_COL_WIDTH}>
                   <FormControl componentClass="select" {...servicePointCloudCompressionInput}
                                onChange={this.handlePointCloudCompressionChange.bind(this, this.props)}>
                     {renderSameAsSourceCompression ?
                      <option key="Same as source" value=''>
                        {this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.sameAsSource)}
                      </option> : null
                     }
                     {this.renderPointCloudCompressionTypes()}
                   </FormControl>
                 </Col>
                 <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                   <HelpBlock>
                     <FormattedMessage
                         id="studio.create-service-form.point-cloud-compression-description"
                         defaultMessage="Point cloud compression reduces content size and loading times, but requires a compatible client."
                     />
                   </HelpBlock>
                 </Col>
               </FormGroup> : null
              }
              <FormGroup>
                <Col sm={LABEL_COL_WIDTH}><ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.endpoint-url"
                    defaultMessage="Endpoint URL"/></ControlLabel></Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <UrlComponent api={this.props.api} serviceType={serviceTypeInput.value}
                                serviceName={serviceNameInput.value} intl={this.props.intl}/>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.abstract"
                                                  defaultMessage="Abstract"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl componentClass="textarea" {...serviceAbstract.input}/>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.keywords"
                                                  defaultMessage="Keywords"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl type="text" {...serviceKeywords.input}/>
                </Col>
                <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                  <HelpBlock>
                    <FormattedMessage id="studio.services.create-service-form.keywords-help"
                                      defaultMessage="Enter a comma-separated list of keywords. For example: satellite,multispectral,landsat"/>
                  </HelpBlock>
                </Col>
              </FormGroup>
              {supportsAccessConstraints(serviceTypeInput.value) ?
               <FormGroup>
                 <Col sm={LABEL_COL_WIDTH}>
                   <ControlLabel><FormattedMessage id="studio.services.create-service-form.access-constraint"
                                                   defaultMessage="AccessConstraint"/></ControlLabel>
                 </Col>
                 <Col sm={12 - LABEL_COL_WIDTH}>
                   <FormControl type="text" {...serviceAccessConstraint.input}/>
                 </Col>
               </FormGroup> : null
              }
              <FormGroup>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-start"
                                                  defaultMessage="Start service?"/></ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <FormControl componentClass={Checkbox}
                               checked={immediatelyStartService.input.value}
                               {...immediatelyStartService.input}/>
                </Col>
              </FormGroup>
              {
                  renderServicePreprocessingPathField &&
                  <FormGroup>
                    <Col sm={LABEL_COL_WIDTH}>
                      <ControlLabel>
                        <FormattedMessage
                            id="studio.create-service-form.preprocessing-output-path"
                            defaultMessage="Output Path"
                        />
                      </ControlLabel>
                    </Col>
                    <Col sm={12 - LABEL_COL_WIDTH}>
                      <InputGroup>
                        <FormControl disabled={true} className={"browse-data-root-input"} type="text"
                                     value={preprocessingPath}/>
                        <InputGroupAddon className={"browse-data-root-button"} onClick={this.showChooseDataRootDialog}>
                          <InputGroupButton>
                            <LcdIcon icon="folder-open"/>
                          </InputGroupButton>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                      <HelpBlock>
                        <FormattedMessage
                            id="studio.create-service-form.preprocessing-output-path-description"
                            defaultMessage="Output directory where the preprocessing results will be stored."
                        />
                      </HelpBlock>
                    </Col>
                  </FormGroup>
              }
              <FormGroup>
                <ChooseFolderDialog handleChoose={this.handleChooseDataRoot}
                                    show={showChooseDataRootDialog}
                                    onHide={this.closeChooseDataRootDialog}
                                    onFolderChoosed={this.closeChooseDataRootDialog}
                                    initialPath={this._defaultPreprocessingPath}
                                    title={intl.formatMessage(SERVICE_FORM_MESSAGES.chooseOutputDirectory)}
                                    buttonText={intl.formatMessage(SERVICE_FORM_MESSAGES.chooseLocation)}
                />
              </FormGroup>
              {
                supportsContactInfo(serviceTypeInput.value) ? this.renderContactInfoSummary() : null
              }
            </div>
          </Tab>
          {
            supportsContactInfo(serviceTypeInput.value) ? this.renderContactInfoTab() : null
          }
          <Tab eventKey={TabKey.PRODUCTS} title={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.productsLabel)}
               tabClassName={this.productTabClassName(serviceTypeInput.value)}>
            <div className="form-tab-content">
              <FormGroup>
                {this.createGeneralValidationMessages(this.state.serviceTypeValidation, serviceTypeInput)}
                <ServiceProductList items={serviceProductsInput.value}
                                    onReorder={(oldIndex, newIndex, items) => {
                                      const products = moveItem<Product>(items, oldIndex, newIndex);
                                      this.updateProducts(serviceProductsInput, products);
                                    }}
                                    onAdd={(newProductItem) => {
                                      const products = [newProductItem, ...serviceProductsInput.value];
                                      this.updateProducts(serviceProductsInput, products);
                                    }}
                                    onRemove={(item, index) => {
                                      const products: Product[] = removeItem(serviceProductsInput.value, index);
                                      this.updateProducts(serviceProductsInput, products);
                                    }}
                                    serviceType={serviceTypeInput.value}
                                    noLinks
                                    showValidationBadge={true}
                                    calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(7,
                                        SERVICE_PRODUCT_LIST_ROW_HEIGHT)}
                />
              </FormGroup>
            </div>
          </Tab>
        </Tabs>
    );
  }

  renderContactInfoSummary = () => {
    const {
      serviceContactIndividualName,
      serviceContactOrganizationName,
      serviceContactPosition,
      serviceContactDeliveryPoint,
      serviceContactCity,
      serviceContactPostCode,
    } = this.props;

    const individualName = serviceContactIndividualName.input.value;
    const organizationName = serviceContactOrganizationName.input.value;
    const positionName = serviceContactPosition.input.value;
    const deliveryPoint = serviceContactDeliveryPoint.input.value;
    const city = serviceContactCity.input.value;
    const postCode = serviceContactPostCode.input.value;

    return (
        <div onClick={this.handleContactInfoPanelClicked.bind(this)}>
          <FormGroup>
            <Col sm={LABEL_COL_WIDTH}><ControlLabel><FormattedMessage
                id="studio.services.service-detail.contact-info-header"
                defaultMessage="Contact Information"/></ControlLabel></Col>
            <Col id="contact-info-summary" sm={12 - LABEL_COL_WIDTH}>
              <ControlLabel>
                <p>
                  {individualName ? positionName + ' ' + individualName : ''}
                  {organizationName ? (individualName ? ' at ' : ' for ') +
                                      organizationName : ''}
                </p>
                <p>
                  {deliveryPoint}
                  {deliveryPoint && (postCode || city) ? ', ' : ''}
                  {postCode + ' '}
                  {city}
                </p>
              </ControlLabel>
              <Glyphicon glyph="pencil" className="pull-right"/>
            </Col>
          </FormGroup>
        </div>
    );
  }

  renderContactInfoTab = () => {
    const {
      serviceContactIndividualName,
      serviceContactOrganizationName,
      serviceContactPosition,
      serviceContactDeliveryPoint,
      serviceContactCity,
      serviceContactAdministrativeArea,
      serviceContactPostCode,
      serviceContactCountry,
      serviceContactFax,
      serviceContactPhone,
      serviceContactEmail
    } = this.props;

    return (
        <Tab eventKey={TabKey.CONTACT_INFO}
             title={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.contactInfoLabel)}>
          <div className="form-tab-content">
            <FormGroup controlId={serviceContactIndividualName.input.name}
                       validationState={serviceContactIndividualName.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.service-contact-individual-name"
                    defaultMessage="Individual Name"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactIndividualName.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactOrganizationName.input.name}
                       validationState={serviceContactOrganizationName.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.service-contact-organization-name"
                    defaultMessage="Organization Name"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactOrganizationName.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactPosition.input.name}
                       validationState={serviceContactPosition.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.service-contact-position"
                    defaultMessage="Position"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactPosition.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactDeliveryPoint.input.name}
                       validationState={serviceContactDeliveryPoint.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.service-contact-delivery-point"
                    defaultMessage="Address"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactDeliveryPoint.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactCity.input.name}
                       validationState={serviceContactCity.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-city"
                                                defaultMessage="City"/></ControlLabel>
              </Col>
              <Col sm={6 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactCity.input} />
              </Col>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-post-code"
                                                defaultMessage="Post Code"/></ControlLabel>
              </Col>
              <Col sm={6 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactPostCode.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactAdministrativeArea.input.name}
                       validationState={serviceContactAdministrativeArea.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage
                    id="studio.services.create-service-form.service-contact-administrative-area"
                    defaultMessage="Administrative Area"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactAdministrativeArea.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactCountry.input.name}
                       validationState={serviceContactCountry.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-country"
                                                defaultMessage="Country"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactCountry.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactFax.input.name}
                       validationState={serviceContactFax.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-fax"
                                                defaultMessage="Fax"/></ControlLabel>
              </Col>
              <Col sm={6 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactFax.input} />
              </Col>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-phone"
                                                defaultMessage="Phone"/></ControlLabel>
              </Col>
              <Col sm={6 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactPhone.input} />
              </Col>
            </FormGroup>
            <FormGroup controlId={serviceContactEmail.input.name}
                       validationState={serviceContactEmail.meta.error ? "error" : null}>
              <Col sm={LABEL_COL_WIDTH}>
                <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-contact-email"
                                                defaultMessage="Email"/></ControlLabel>
              </Col>
              <Col sm={12 - LABEL_COL_WIDTH}>
                <FormControl type="text" {...serviceContactEmail.input} />
              </Col>
            </FormGroup>
          </div>
        </Tab>
    );
  }

  updateProducts = (serviceProductsInput: any, products: Product[]) => {
    this.props.api.serviceTypeValidation(products).then(serviceTypeValidation => {
      addValidationToProducts(serviceTypeValidation, products);
      this.setState({serviceTypeValidation, products}, () => {
        this.configureCompressionState();
      });
      serviceProductsInput.onChange(products);
    })
  }

  private createGeneralValidationMessages(serviceTypeValidation, serviceTypeInput) {
    const messages = [];
    if (serviceTypeValidation) {
      serviceTypeValidation.serviceTypes.forEach((serviceType) => {
        if (serviceType.name === serviceTypeInput.value) {
          serviceType.validationMessages.forEach((validationMessage) => {
            if (!validationMessage.product) {
              if (!validationMessage.severity || validationMessage.severity === ValidationMessageSeverity.ERROR) {
                messages.push(<Alert key="general-danger" bsStyle="danger"><b><FormattedMessage
                    id="studio.create-service-form.general-error"
                    defaultMessage="ERROR:"/></b>&nbsp;{validationMessage.message}</Alert>);
              } else {
                messages.push(<Alert key="general-warning" bsStyle="warning"><b><FormattedMessage
                    id="studio.create-service-form.general-warning"
                    defaultMessage="WARNING:"/></b>&nbsp;{validationMessage.message}</Alert>);
              }
            }
          });
        }
      });
    }
    return messages;
  }

}

interface CreateServiceFormOwnProps {
  inputProducts?: PendingProductForService[];
  initServiceTypeValidation?: ServiceTypeValidation;
}

export type CreateServiceFormProps = CreateServiceFormOwnProps & InjectedIntlProps & WithApiProperties;

interface CreateServiceFormState {
  formError?: string;
}

// Used to "catch" newly created service for linking, which happens while submitting the form.
let _newService = null;

class CreateServiceFormReactComponent extends React.Component<CreateServiceFormProps & InjectedFormProps<CreateServiceFormFieldsData, CreateServiceFormProps>, CreateServiceFormState> {

  _logger: Logger = Logger.getLogger("services.CreateServiceForm.CreateServiceFormReactComponent");

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {inputProducts = []} = this.props;

    _newService = null;

    this.props.initialize({
      immediatelyStartService: true,
      serviceTitle: "",
      serviceName: "",
      serviceType: DEFAULT_SERVICE_TYPE_DETAILS.serviceType,
      serviceAbstract: OFFLINE_METADATA.abstractText || "",
      serviceKeywords: "",
      serviceAccessConstraint: OFFLINE_METADATA.accessConstraint || "",
      serviceMeshCompression: MeshCompression.NONE,
      servicePointCloudCompression: PointCloudCompression.NONE,
      serviceProducts: [...inputProducts],
      servicePreprocessingPath: "",
      serviceContactIndividualName: OFFLINE_METADATA.responsibleParty.individualName || "",
      serviceContactOrganizationName: OFFLINE_METADATA.responsibleParty.organizationName || "",
      serviceContactPosition: OFFLINE_METADATA.responsibleParty.position || "",
      serviceContactDeliveryPoint: OFFLINE_METADATA.responsibleParty.address.deliveryPoint || "",
      serviceContactCity: OFFLINE_METADATA.responsibleParty.address.city || "",
      serviceContactAdministrativeArea: OFFLINE_METADATA.responsibleParty.address.administrativeArea || "",
      serviceContactPostCode: OFFLINE_METADATA.responsibleParty.address.postCode || "",
      serviceContactCountry: OFFLINE_METADATA.responsibleParty.address.country || "",
      serviceContactFax: OFFLINE_METADATA.responsibleParty.fax || "",
      serviceContactPhone: OFFLINE_METADATA.responsibleParty.phone || "",
      serviceContactEmail: (OFFLINE_METADATA.responsibleParty.email &&
                            OFFLINE_METADATA.responsibleParty.email.length >= 1)
                           ? OFFLINE_METADATA.responsibleParty.email[0] : "",
    });
  }

  confirmationComponent = (newService: Service) => {
    return (
        <div className="form-message">
          <h2><FormattedMessage
              id="studio.create-service-form.service-created"
              defaultMessage="Service successfully created"
          /></h2>
          {newService && newService.status && newService.status === ServiceStatus.PENDING &&
           <h4><FormattedMessage
               id="studio.create-service-form.service-pending"
               defaultMessage="Service is not running yet, a preprocessing job is working for the service"
           /></h4>
          }
          {newService && <Link to={toServiceLink(newService)} className="button-link">
            <Button bsStyle="info">
              <span><FormattedMessage id="studio.services.create-service-form.title"
                                      defaultMessage="Go To Service {name}"
                                      values={{name: newService.name}}/></span>
            </Button>
          </Link>}
        </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.submitSucceeded && this.state.formError) {
      this.setState({formError: undefined});
    } else if (this.props.error && this.props.error !== prevProps.error && this.props.error !== this.state.formError) {
      this.setState({formError: this.props.error})
    }
  }

  render() {
    const {submitFailed, submitting, submitSucceeded, handleSubmit, intl} = this.props;
    const {formError} = this.state;
    const errorContent = submitFailed && formError ? (
        <div>
          <Alert key="alert-danger" bsStyle="danger">
            <strong>
              {intl.formatMessage(SERVICE_FORM_MESSAGES.submitFailed)}
            </strong> {formError}
          </Alert>
        </div>
    ) : null;
    if (submitting) {
      return (
          <div className="form-message">
            <h1>{intl.formatMessage(SERVICE_FORM_MESSAGES.submitting)}</h1>
          </div>
      );
    }
    if (submitSucceeded) {
      return this.confirmationComponent(_newService);
    }

    return (
        <div>
          {errorContent}
          <Form horizontal onSubmit={handleSubmit}>
            <Fields
                names={[FIELD_SERVICE_TITLE, FIELD_SERVICE_ABSTRACT, FIELD_SERVICE_KEYWORDS, FIELD_SERVICE_START,
                        FIELD_SERVICE_TYPE, FIELD_SERVICE_MESH_COMPRESSION, FIELD_SERVICE_POINT_CLOUD_COMPRESSION,
                        FIELD_SERVICE_NAME, FIELD_SERVICE_ACCESS_CONSTRAINT,
                        FIELD_SERVICE_PRODUCTS,
                        FIELD_SERVICE_PREPROCESSING_PATH,
                        FIELD_SERVICE_CONTACT_INDIVIDUAL_NAME, FIELD_SERVICE_CONTACT_ORGANIZATION_NAME,
                        FIELD_SERVICE_CONTACT_POSITION,
                        FIELD_SERVICE_CONTACT_DELIVERY_POINT, FIELD_SERVICE_CONTACT_CITY,
                        FIELD_SERVICE_CONTACT_ADMINISTRATIVE_AREA, FIELD_SERVICE_CONTACT_POST_CODE,
                        FIELD_SERVICE_CONTACT_COUNTRY, FIELD_SERVICE_CONTACT_FAX, FIELD_SERVICE_CONTACT_PHONE,
                        FIELD_SERVICE_CONTACT_EMAIL]}
                component={CreateServiceFormFields}
                validate={serviceFormFieldValidators}

                {...this.props}
            />
          </Form>
        </div>
    );
  }
}

const serviceFormFieldValidators = {
  [FIELD_SERVICE_TITLE]: (value) => {
    if (!value || value.length === 0) {
      return <FormattedMessage
          id="studio.create-service-form.title-error"
          defaultMessage="Required"
      />;
    }
  },
  [FIELD_SERVICE_NAME]: (value) => {
    if (!value || value.length === 0) {
      return <FormattedMessage id="studio.create-service-form.name-error-required" defaultMessage="Required"/>;
    } else if (toSafeName(value).length === 0) {
      return <FormattedMessage id="studio.create-service-form.name-error-invalid"
                               defaultMessage="Given name is not valid. Symbols are not allowed"/>;
    }
  },
  [FIELD_SERVICE_PRODUCTS]: (value, allValues) => {
    if (value && allValues[FIELD_SERVICE_TYPE] &&
        !productValidation.areProductsOK(value, allValues[FIELD_SERVICE_TYPE])) {
      return <FormattedMessage id="studio.create-service-form.product-error"
                               defaultMessage="Some products cannot be used with this service"/>;
    }
  },
}

const submitCreateServiceForm = (values: CreateServiceFormFieldsData, dispatch, props: CreateServiceFormProps) => {
  const name = toSafeName(values[FIELD_SERVICE_NAME]);
  const serviceType = values[FIELD_SERVICE_TYPE];
  const preprocessingOutputPath = values[FIELD_SERVICE_PREPROCESSING_PATH];
  const {intl} = props;

  let service: Service = {
    id: null,
    name,
    canDelete: false,
    type: serviceType,
    title: values[FIELD_SERVICE_TITLE],
    abstractText: values.serviceAbstract,
    keywords: !values.serviceKeywords ? null : values.serviceKeywords.split(",").filter(
        (keyword) => keyword.length !== 0),
    accessConstraint: values[FIELD_SERVICE_ACCESS_CONSTRAINT],
    meshCompression: !values[FIELD_SERVICE_MESH_COMPRESSION] ? null : values[FIELD_SERVICE_MESH_COMPRESSION],
    pointCloudCompression: !values[FIELD_SERVICE_POINT_CLOUD_COMPRESSION] ? null
                                                                          : values[FIELD_SERVICE_POINT_CLOUD_COMPRESSION],
    contactInformation: {
      individualName: values[FIELD_SERVICE_CONTACT_INDIVIDUAL_NAME],
      organizationName: values[FIELD_SERVICE_CONTACT_ORGANIZATION_NAME],
      position: values[FIELD_SERVICE_CONTACT_POSITION],
      deliveryPoint: values[FIELD_SERVICE_CONTACT_DELIVERY_POINT],
      city: values[FIELD_SERVICE_CONTACT_CITY],
      administrativeArea: values[FIELD_SERVICE_CONTACT_ADMINISTRATIVE_AREA],
      postCode: values[FIELD_SERVICE_CONTACT_POST_CODE],
      country: values[FIELD_SERVICE_CONTACT_COUNTRY],
      fax: values[FIELD_SERVICE_CONTACT_FAX],
      phone: values[FIELD_SERVICE_CONTACT_PHONE],
      email: values[FIELD_SERVICE_CONTACT_EMAIL],
    }
  };

  service = Object.assign(service, preprocessingOutputPath && {preprocessingOutputPath});

  let serviceId;
  const products = values[FIELD_SERVICE_PRODUCTS];
  const maybeStartService = () => values.immediatelyStartService && dispatch(actions.startService(serviceId));

  let resolveFunc, rejectFunc;
  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });

  dispatch(actions.loadEnabledServiceTypes()).then((serviceConfigurations) => {
    return validateAgainstServiceConfiguration(service, serviceConfigurations, products, intl)
        .then((aService) => dispatch(actions.createService(aService)))
        .then((newService) => {
          serviceId = newService.id;
          _newService = newService;
          // Since API and GUI list products in opposite order, reverse before we send to API.
          return Promise.resolve(
              dispatch(actions.addProductsToService(serviceId, products.map((a) => a.id).reverse())));
        }).then(maybeStartService)
        .then((action) => {
          if (action) {
            _newService = action.payload.service;
          }
          return resolveFunc();
        });
  }).catch((error) => {
    const errorMessage = (error.response && error.response.data &&
                          (error.response.data.message || error.response.data.details) ||
                          error.message);
    rejectFunc(new SubmissionError({_error: errorMessage}));
  });

  return promise;
};

const validateAgainstServiceConfiguration = (service: Service,
                                             serviceConfigurations: ServiceTypeDetails[],
                                             products: Product[],
                                             intl: InjectedIntl) => {
  const serviceConfiguration = serviceConfigurations.find(
      (serviceConf) => serviceConf.serviceType === service.type);
  if (serviceConfiguration) {
    const maxAllowedProductCount = serviceConfiguration.maxAllowedProductCount;
    if (maxAllowedProductCount && maxAllowedProductCount < products.length) {
      return Promise.reject({
        response: {
          data: {
            message: intl.formatMessage(SERVICE_FORM_MESSAGES.productsCountError, {
              type: serviceConfiguration.serviceType.toLocaleUpperCase(),
              count: maxAllowedProductCount,
            }),
          },
        },
      });
    }
    return Promise.resolve(service);
  }

  return Promise.reject({
    response: {
      data: {
        message: intl.formatMessage(SERVICE_FORM_MESSAGES.unknownTypeError, {type: service.type}),
      },
    },
  });
};

export const CreateServiceFormComponent = CreateServiceFormReactComponent;

const mapStateToProps = (state) => {
  return {
    defaultMetadata: defaultMetadataSelectors.getDefaultMetadata(state),
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadDefaultMetadata: (): Promise<void> => {
      return dispatch(defaultMetadataActions.loadDefaultMetadata());
    },
  };
}

const CreateServiceFormFields = connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(WithApi(CreateServiceFormFieldsComponent)));

export const FORM_NAME = "createServiceForm";
const formConfig = {
  form: FORM_NAME,
  onSubmit: submitCreateServiceForm,
};

export const CreateServiceForm = injectIntl(
    WithApi(reduxForm<CreateServiceFormFieldsData, CreateServiceFormProps>(formConfig)(CreateServiceFormComponent)));

interface CreateServiceFormSubmitButtonProps {
  dispatch: Dispatch<Action>;
  pristine: boolean;
  submitting: boolean;
  submitSucceeded: boolean;
  syncErrors: FormErrors<any>;
}

class CreateServiceFormSubmitButtonComponent extends React.Component
                                                         <CreateServiceFormSubmitButtonProps & InjectedIntlProps, {}> {
  render() {
    const {dispatch, pristine, submitting, submitSucceeded, syncErrors} = this.props;
    if (submitting || submitSucceeded) {
      return null;
    }
    return (
        <Button disabled={pristine || !isEmpty(syncErrors)} onClick={() => dispatch(submit(FORM_NAME))}>
          <FormattedMessage
              id="studio.create-service-form.button"
              defaultMessage="Create Service"
          />
        </Button>
    );
  }
}

export const CreateServiceFormSubmitButton = connect((state) => {
  return {
    pristine: isPristine(FORM_NAME)(state),
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    syncErrors: getFormSyncErrors(FORM_NAME)(state),
  };
})(injectIntl(CreateServiceFormSubmitButtonComponent));

export const CloseCreateServiceFormButton = injectIntl(createCloseButton(FORM_NAME));
